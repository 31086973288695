import React from 'react';
import Modal from './Modal';
export default class ImageComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      showModal: false,
      imageHasLoaded: false
    };
  }

  toggleModal() {
    this.setState({
      showModal: !this.state.showModal
    });
  }

  handleImageLoaded() {
    this.setState({ imageHasLoaded: true});
  }

  render() {
    const {image, isUploading} = this.props;
    const {showModal, imageHasLoaded } = this.state;

    return (
      <div style={{cursor: 'pointer', position: 'relative', marginBottom: '8px'}}>
        <img
          style={{maxWidth: '100%'}}
          onLoad={this.handleImageLoaded.bind(this)}
          src={image ? image : 'http://via.placeholder.com/1280x720'} 
          onClick={() => this.toggleModal()}
        />
        {isUploading ? (
          <div className="uploadingBackground">
            <h2>Laddar upp ... vänta</h2>
          </div>
        ) : null}
        {!imageHasLoaded ? (
          <div 
            className="animatedBackground imagePlaceholder">
            <div className="sun"></div>
            <div className="smallMountain"></div>
            <div className="bigMountain"></div>
          </div>  )
          : null
        }       
        <Modal show={showModal} transitionSpeed={1} closeOnClickAnywhere onClose={() => this.toggleModal()}>
          <img 
            onLoad={this.handleImageLoaded.bind(this)}
            src={image ? image : 'http://via.placeholder.com/1000x1000'} style={{
              maxWidth: '100%'
            }}
          />
        </Modal>
      </div>
    );
  }
}