import React from 'react';
import {NavLink} from 'react-router-dom';

export default class Logo extends React.Component {
  render() {
    return (
      <NavLink to="/" exact className="logo">
        Johannes Hägglund
      </NavLink>
    );
  }
}
