'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

function _interopDefault (ex) { return (ex && (typeof ex === 'object') && 'default' in ex) ? ex['default'] : ex; }

var React = require('react');
var React__default = _interopDefault(React);
var PropTypes = _interopDefault(require('prop-types'));

var classCallCheck = function (instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
};

var createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();







var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};



var inherits = function (subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
};









var objectWithoutProperties = function (obj, keys) {
  var target = {};

  for (var i in obj) {
    if (keys.indexOf(i) >= 0) continue;
    if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
    target[i] = obj[i];
  }

  return target;
};

var possibleConstructorReturn = function (self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
};





var slicedToArray = function () {
  function sliceIterator(arr, i) {
    var _arr = [];
    var _n = true;
    var _d = false;
    var _e = undefined;

    try {
      for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
        _arr.push(_s.value);

        if (i && _arr.length === i) break;
      }
    } catch (err) {
      _d = true;
      _e = err;
    } finally {
      try {
        if (!_n && _i["return"]) _i["return"]();
      } finally {
        if (_d) throw _e;
      }
    }

    return _arr;
  }

  return function (arr, i) {
    if (Array.isArray(arr)) {
      return arr;
    } else if (Symbol.iterator in Object(arr)) {
      return sliceIterator(arr, i);
    } else {
      throw new TypeError("Invalid attempt to destructure non-iterable instance");
    }
  };
}();













var toConsumableArray = function (arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i];

    return arr2;
  } else {
    return Array.from(arr);
  }
};

var Firestore = function (_Component) {
  inherits(Firestore, _Component);

  function Firestore() {
    classCallCheck(this, Firestore);
    return possibleConstructorReturn(this, (Firestore.__proto__ || Object.getPrototypeOf(Firestore)).apply(this, arguments));
  }

  createClass(Firestore, [{
    key: 'render',
    value: function () {
      var firestoreDatabase = this.context.firestoreDatabase;
      var render = this.props.render;


      return render({ firestore: firestoreDatabase });
    }
  }]);
  return Firestore;
}(React.Component);

Firestore.propTypes = {
  render: PropTypes.func.isRequired
};
Firestore.contextTypes = {
  firestoreDatabase: PropTypes.object.isRequired
};

var FirestoreCache = function FirestoreCache() {
  classCallCheck(this, FirestoreCache);
};

var FirestoreProvider = function (_Component) {
  inherits(FirestoreProvider, _Component);

  function FirestoreProvider(props) {
    classCallCheck(this, FirestoreProvider);

    var _this = possibleConstructorReturn(this, (FirestoreProvider.__proto__ || Object.getPrototypeOf(FirestoreProvider)).call(this, props));

    var firebase = props.firebase;


    _this.state = {
      firestoreDatabase: firebase.firestore(),
      firestoreCache: new FirestoreCache()
    };
    return _this;
  }

  createClass(FirestoreProvider, [{
    key: 'getChildContext',
    value: function getChildContext() {
      var _state = this.state,
          firestoreDatabase = _state.firestoreDatabase,
          firestoreCache = _state.firestoreCache;


      return { firestoreDatabase: firestoreDatabase, firestoreCache: firestoreCache };
    }
  }, {
    key: 'render',
    value: function render() {
      return this.props.children;
    }
  }]);
  return FirestoreProvider;
}(React.Component);

FirestoreProvider.propTypes = {
  firebase: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired
};
FirestoreProvider.childContextTypes = {
  firestoreDatabase: PropTypes.object.isRequired,
  firestoreCache: PropTypes.object.isRequired
};

/**
 * Deep equality comparison for Arrays
 * @param {Array} a The array to compare against
 * @param {Array} b The array to compare with
 * @returns {boolean} If the two arrays are equal
 */
function deepEqual(a, b) {
  if (Array.isArray(a) && Array.isArray(b)) {
    if (a.length !== b.length) {
      return false;
    }

    for (var i = 0; i < a.length; i++) {
      if (!deepEqual(a[i], b[i])) {
        return false;
      }
    }

    return true;
  } else {
    return a === b;
  }
}

var FirestoreCollection = function (_Component) {
  inherits(FirestoreCollection, _Component);

  function FirestoreCollection() {
    var _ref;

    var _temp, _this, _ret;

    classCallCheck(this, FirestoreCollection);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = possibleConstructorReturn(this, (_ref = FirestoreCollection.__proto__ || Object.getPrototypeOf(FirestoreCollection)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      isLoading: true,
      data: [],
      snapshot: null
    }, _this.setupFirestoreListener = function (props) {
      var firestoreDatabase = _this.context.firestoreDatabase;
      var path = props.path,
          queryProps = objectWithoutProperties(props, ['path']);

      var collectionRef = firestoreDatabase.collection(path);
      var query = _this.buildQuery(collectionRef, queryProps);

      _this.unsubscribe = query.onSnapshot(function (snapshot) {
        if (snapshot) {
          _this.setState({
            isLoading: false,
            data: snapshot.docs.map(function (doc) {
              return _extends({
                id: doc.id
              }, doc.data());
            }),
            snapshot: snapshot
          });
        }
      });
    }, _this.buildQuery = function (collectionRef, queryProps) {
      var sort = queryProps.sort,
          limit = queryProps.limit,
          filter = queryProps.filter;

      var query = collectionRef;

      if (sort) {
        sort.split(',').forEach(function (sortItem) {
          var _sortItem$split = sortItem.split(':'),
              _sortItem$split2 = slicedToArray(_sortItem$split, 2),
              field = _sortItem$split2[0],
              order = _sortItem$split2[1];

          query = query.orderBy(field, order);
        });
      }

      if (limit) {
        query = query.limit(limit);
      }

      if (filter) {
        //if filter is array of array, build the compound query
        if (Array.isArray(filter[0])) {
          filter.forEach(function (clause) {
            var _query;

            query = (_query = query).where.apply(_query, toConsumableArray(clause));
          });
        } else {
          var _query2;

          //build the simple query
          query = (_query2 = query).where.apply(_query2, toConsumableArray(filter));
        }
      }

      return query;
    }, _temp), possibleConstructorReturn(_this, _ret);
  }

  createClass(FirestoreCollection, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      this.setupFirestoreListener(this.props);
    }
  }, {
    key: 'componentWillUnmount',
    value: function componentWillUnmount() {
      this.handleUnsubscribe();
    }
  }, {
    key: 'componentWillReceiveProps',
    value: function componentWillReceiveProps(nextProps) {
      var _this2 = this;

      if (nextProps.path !== this.props.path || nextProps.sort !== this.props.sort || nextProps.limit !== this.props.limit || !deepEqual(nextProps.filter, this.props.filter)) {
        this.handleUnsubscribe();

        this.setState({ isLoading: true }, function () {
          return _this2.setupFirestoreListener(_this2.props);
        });
      }
    }
  }, {
    key: 'handleUnsubscribe',
    value: function handleUnsubscribe() {
      if (this.unsubscribe) {
        this.unsubscribe();
      }
    }
  }, {
    key: 'render',
    value: function () {
      var _state = this.state,
          isLoading = _state.isLoading,
          data = _state.data,
          snapshot = _state.snapshot;
      var render = this.props.render;


      return render({
        isLoading: isLoading,
        data: data,
        snapshot: snapshot
      });
    }
  }]);
  return FirestoreCollection;
}(React.Component);

FirestoreCollection.propTypes = {
  path: PropTypes.string.isRequired,
  sort: PropTypes.string,
  limit: PropTypes.number,
  filter: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.arrayOf(PropTypes.array)]),
  render: PropTypes.func.isRequired
};
FirestoreCollection.contextTypes = {
  firestoreDatabase: PropTypes.object.isRequired,
  firestoreCache: PropTypes.object.isRequired
};

var FirestoreDocument = function (_Component) {
  inherits(FirestoreDocument, _Component);

  function FirestoreDocument() {
    var _ref;

    var _temp, _this, _ret;

    classCallCheck(this, FirestoreDocument);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = possibleConstructorReturn(this, (_ref = FirestoreDocument.__proto__ || Object.getPrototypeOf(FirestoreDocument)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      isLoading: true,
      data: null,
      snapshot: null
    }, _this.setupFirestoreListener = function (props) {
      var firestoreDatabase = _this.context.firestoreDatabase;
      var path = props.path;

      var documentRef = firestoreDatabase.doc(path);

      _this.unsubscribe = documentRef.onSnapshot(function (snapshot) {
        if (snapshot) {
          _this.setState({
            isLoading: false,
            data: _extends({
              id: snapshot.id
            }, snapshot.data()),
            snapshot: snapshot
          });
        }
      });
    }, _temp), possibleConstructorReturn(_this, _ret);
  }

  createClass(FirestoreDocument, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      this.setupFirestoreListener(this.props);
    }
  }, {
    key: 'componentWillUnmount',
    value: function componentWillUnmount() {
      this.handleUnsubscribe();
    }
  }, {
    key: 'componentWillReceiveProps',
    value: function componentWillReceiveProps(nextProps) {
      var _this2 = this;

      if (nextProps.path !== this.props.path) {
        this.handleUnsubscribe();

        this.setState({ isLoading: true }, function () {
          return _this2.setupFirestoreListener(_this2.props);
        });
      }
    }
  }, {
    key: 'handleUnsubscribe',
    value: function handleUnsubscribe() {
      if (this.unsubscribe) {
        this.unsubscribe();
      }
    }
  }, {
    key: 'render',
    value: function () {
      var _state = this.state,
          isLoading = _state.isLoading,
          data = _state.data,
          snapshot = _state.snapshot;
      var render = this.props.render;


      return render({
        isLoading: isLoading,
        data: data,
        snapshot: snapshot
      });
    }
  }]);
  return FirestoreDocument;
}(React.Component);

FirestoreDocument.propTypes = {
  path: PropTypes.string.isRequired,
  render: PropTypes.func.isRequired
};
FirestoreDocument.contextTypes = {
  firestoreDatabase: PropTypes.object.isRequired,
  firestoreCache: PropTypes.object.isRequired
};

/**
 * Copyright 2015, Yahoo! Inc.
 * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
 */
var REACT_STATICS = {
    childContextTypes: true,
    contextTypes: true,
    defaultProps: true,
    displayName: true,
    getDefaultProps: true,
    mixins: true,
    propTypes: true,
    type: true
};

var KNOWN_STATICS = {
  name: true,
  length: true,
  prototype: true,
  caller: true,
  callee: true,
  arguments: true,
  arity: true
};

var defineProperty$1 = Object.defineProperty;
var getOwnPropertyNames = Object.getOwnPropertyNames;
var getOwnPropertySymbols = Object.getOwnPropertySymbols;
var getOwnPropertyDescriptor = Object.getOwnPropertyDescriptor;
var getPrototypeOf = Object.getPrototypeOf;
var objectPrototype = getPrototypeOf && getPrototypeOf(Object);

var hoistNonReactStatics = function hoistNonReactStatics(targetComponent, sourceComponent, blacklist) {
    if (typeof sourceComponent !== 'string') { // don't hoist over string (html) components

        if (objectPrototype) {
            var inheritedComponent = getPrototypeOf(sourceComponent);
            if (inheritedComponent && inheritedComponent !== objectPrototype) {
                hoistNonReactStatics(targetComponent, inheritedComponent, blacklist);
            }
        }

        var keys = getOwnPropertyNames(sourceComponent);

        if (getOwnPropertySymbols) {
            keys = keys.concat(getOwnPropertySymbols(sourceComponent));
        }

        for (var i = 0; i < keys.length; ++i) {
            var key = keys[i];
            if (!REACT_STATICS[key] && !KNOWN_STATICS[key] && (!blacklist || !blacklist[key])) {
                var descriptor = getOwnPropertyDescriptor(sourceComponent, key);
                try { // Avoid failures from read-only properties
                    defineProperty$1(targetComponent, key, descriptor);
                } catch (e) {}
            }
        }

        return targetComponent;
    }

    return targetComponent;
};

var withFirestore = function (Component$$1) {
  var C = function (props) {
    var wrappedComponentRef = props.wrappedComponentRef,
        remainingProps = objectWithoutProperties(props, ['wrappedComponentRef']);

    return React__default.createElement(Firestore, {
      render: function render(firestoreComponentProps) {
        return React__default.createElement(Component$$1, _extends({}, remainingProps, firestoreComponentProps, {
          ref: wrappedComponentRef
        }));
      }
    });
  };

  C.displayName = 'withFirestore(' + (Component$$1.displayName || Component$$1.name) + ')';
  C.WrappedComponent = Component$$1;
  C.propTypes = {
    wrappedComponentRef: PropTypes.func
  };

  return hoistNonReactStatics(C, Component$$1);
};

exports.Firestore = Firestore;
exports.FirestoreProvider = FirestoreProvider;
exports.FirestoreCollection = FirestoreCollection;
exports.FirestoreDocument = FirestoreDocument;
exports.withFirestore = withFirestore;
