import React from 'react';
import DataDisplayer from '../components/DataDisplayer';

var style = {
  'position':'relative', 
  'padding': '8px'
};

export default class Page extends React.Component {

  render() {
    return (
      <div style={style}>
        <DataDisplayer schemaType={'news'} {...this.props} />
      </div>
    );
  }
}
