import * as React from 'react';
import Input from './Input';
import ImageUploader from './ImageUploader';
import ImageComponent from './ImageComponent';
import {EditButton, Button} from './Button';
import {schema} from '../config';
import * as firebase from 'firebase';
import Modal from './Modal';

export default class DataEditor extends React.Component {
  constructor(props) {
    super(props);
    const { schemaType, item } = this.props;
    const configKeys = schema[schemaType].keys;
    let newItem = {};

    for (var key in configKeys) {
      let newValue = item[key];
      if (!newValue) {newValue = configKeys[key].placeholder;}
      newItem[key] = newValue;
    }
    this.state = {
      item: newItem,
      initialItem: Object.assign({}, newItem),
      editingItem: false,
      showDeleteModal: false
    };
  }

  toggleDeleteModal() {
    this.setState({
      showDeleteModal: !this.state.showDeleteModal
    });
  }

  changeValue(key, value) {
    let tempStateItem = this.state.item;
    tempStateItem[key] = value;
    this.setState(tempStateItem);
  }

  resetState() {
    this.setState({item: this.state.initialItem, editingItem: false});
  }

  toggleEditing() {
    this.setState({editingItem: !this.state.editingItem});
  }

  saveItem() {
    firebase.firestore().collection(this.props.schemaType).doc(this.props.item.id).update(this.state);
    this.setState({initialItem: this.state.item, editingItem: false});
  }

  deleteItem() {
    this.setState({editingItem: false});
    firebase.firestore().collection(this.props.schemaType).doc(this.props.item.id).delete();
    this.toggleDeleteModal();
  }

  uploadImage(event, itemKey, isUploadingFN, uploadedFileFN) {
    const file = event.target.files[0];
    if (file) {
      isUploadingFN(true);
      let that = this;
      let storageRef = firebase.storage().ref();
      storageRef.child(`images/${file.name}`).put(file).then(function(snapshot) {
        that.changeValue(itemKey, snapshot.metadata.downloadURLs[0]);
        isUploadingFN(false);
        uploadedFileFN(snapshot.metadata.downloadURLs[0]);
      });
    }
  }

  isURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return pattern.test(str);
  }

  renderItem(configValues, key, value) {
    const {editingItem} = this.state;
    const {type, placeholder} = configValues;
    switch(type) {
      case('created'):
        return null;
      case('image'):
        return editingItem ? (
            <div key={key}>
              <p>{placeholder}: </p>
              <ImageUploader uploadImage={this.uploadImage.bind(this)} imageUrl={this.isURL(value) ? value : 'http://via.placeholder.com/1000x1000'} itemKey={key}/>
            </div>
          ) : (
            <ImageComponent key={key} image={this.isURL(value) ? value : 'http://via.placeholder.com/1000x1000'} style={{maxWidth: '100%'}}/>
          );
      case('string'):
        return editingItem ? (
            <div key={key}>
              <p>{placeholder}: </p>
              <Input
                isTextArea
                value={value}
                placeholder={placeholder}
                onChange={
                  (event) => this.changeValue(key, event.target.value)} 
              />
            </div>
          ) : (
            <pre key={key} dangerouslySetInnerHTML={{__html: value}}/>
          );
      case('url'):
        return editingItem ? (
            <div key={key}>
              <p>{placeholder}: </p>
              <Input
                isTextArea={false}
                value={value}
                placeholder={placeholder}
                onChange={
                  (event) => this.changeValue(key, event.target.value)} 
              />
            </div>
          ) : (
            <a key={key} href={value} style={{fontStyle: 'italic'}}>{value}</a>
          );
      default:
        return null;
    }
  }

  renderItems() {
    const {schemaType} = this.props;
    const configKeys = schema[schemaType].keys;
    const itemKeys = Object.keys(this.state.item);
    return (itemKeys).map((itemKey) => {
      return this.renderItem(configKeys[itemKey], itemKey, this.state.item[itemKey]);
    });
  }

  render() {
    const {editing} = this.props;
    const {editingItem, showDeleteModal} = this.state;
    return (
      <div style={{position:'relative'}}>

        <div className={editingItem ? 'editing-item shadow' : null}>
          {this.renderItems()}
        </div>

        {editing ? (
          <div style={absolutePositioningWrapperStyle}>
            <EditButton text={editingItem ? 'Återställ' : 'Redigera'} onClick={
              editingItem ? this.resetState.bind(this)
              : this.toggleEditing.bind(this)
            } style={editingItem ? {marginLeft: '40px'} : null}>
              {editingItem ? (
                <svg transform="rotate(-45)" style={{'verticalAlign':'middle'}} width="24" height="24" viewBox="0 0 24 24">
                  <path fill="#333" d="M12.3,3c-3,0-5.7,1.4-7.4,3.6L3,5v7l6-2L7.2,8.5C8.3,7,10,6,12,6c3.3,0,6,2.8,6,6c0,3.2-2.7,6-6,6c-2,0-3.7-1-4.8-2.5L4.6,17c1.7,2.4,4.5,4,7.7,4c5.1,0,8.8-4,8.8-9C21,7,17.4,3,12.3,3z"/>
                </svg>
              ) : (
                <svg transform="rotate(225)" style={{'verticalAlign':'middle'}} width="20" height="20" viewBox="0 0 1000 1000">
                  <path stroke="#333" fill="#333" d="M954.1,132.5l-86.6-86.6C843.6,22,812.2,10,780.9,10s-62.7,12-86.6,35.9L168.4,571.8c-23.9,23.9-97.1,116.5-97.1,147.8L10,990l270.4-61.2c0,0,123.9-73.2,147.9-97.1l525.9-525.9C1002,257.9,1002,180.3,954.1,132.5z M91.2,908.8L131,733.1c0.6-2.5,1-5.1,1.2-7.6c0.1-0.4,0.5-0.9,0.6-1.3l138.5,138.5c-4.6,2.8-9.3,5.6-13.9,8.4L91.2,908.8z M385,788.2c-6.9,6.6-30.9,22.8-60.2,41.5L166.1,670.9c16.3-22.3,34.4-44.7,45.5-55.9l396-396l173.2,173.2L385,788.2z M910.8,262.4L824.2,349L651,175.8l86.6-86.6c11.5-11.6,26.9-17.9,43.3-17.9s31.8,6.4,43.3,17.9l86.6,86.6c11.6,11.5,17.9,26.9,17.9,43.3C928.7,235.4,922.4,250.9,910.8,262.4z"/>
                </svg>
              )}
            </EditButton>
            {editingItem ? (
              <React.Fragment>
                <EditButton text="Spara" onClick={this.saveItem.bind(this)} style={editingItem ? {marginLeft: '40px'} : null}>
                  <svg transform="rotate(-45)" style={{'verticalAlign':'middle'}} width="28" height="28" viewBox="0 0 96 96">
                    <path fill="green" d="M19.4,18v60h57.1V31.4L65.3,18h-6.9v22.9H28V18H19.4z M42.3,20.9v14.3h11.4V20.9H42.3z M28,50.4
    h40v21.9H28V50.4z M33.7,55.1V58h28.6v-2.9H33.7z M33.7,63.7v2.9h28.6v-2.9H33.7z"/>
                  </svg>
                </EditButton>
                <EditButton text="Ta bort" onClick={this.toggleDeleteModal.bind(this)} style={editingItem ? {marginLeft: '40px'} : null}>
                  <svg transform="rotate(-45)" style={{'verticalAlign':'middle'}} width="24" height="24" viewBox="0 0 791.957  791.957 ">
                    <path fill="red" d="M585.82,235.876H209.179c-39.621,0-71.728,32.082-71.728,71.703l38.865,412.675c0,39.597,32.131,71.703,71.751,71.703
        h304.89c39.62,0,71.751-32.106,71.751-71.703l32.888-412.675C657.547,267.958,625.441,235.876,585.82,235.876z M301.839,666.41
        c0,19.786-16.053,35.814-35.864,35.814c-19.81,0-35.863-16.053-35.863-35.814V343.467c0-19.786,16.053-35.888,35.863-35.888
        c19.811,0,35.864,16.102,35.864,35.888V666.41L301.839,666.41z M427.385,666.41c0,19.786-16.053,35.814-35.863,35.814
        c-19.81,0-35.863-16.053-35.863-35.814V343.467c0-19.786,16.053-35.888,35.863-35.888c19.811,0,35.863,16.102,35.863,35.888
        V666.41z M552.933,666.41c0,19.786-16.054,35.814-35.864,35.814s-35.863-16.053-35.863-35.814V343.467
        c0-19.786,16.053-35.888,35.863-35.888s35.864,16.102,35.864,35.888V666.41z M190.027,200.013L624.27,88.299
        c23.981-6.148,38.425-30.594,32.252-54.576c-6.172-23.982-30.618-38.425-54.6-32.326L468.446,35.748
        c-12.369-18.347-35.205-27.983-57.845-22.177l-52.088,13.443c-22.641,5.831-37.986,25.275-39.987,47.306l-150.847,38.84
        c-23.982,6.148-38.401,30.594-32.253,54.576C141.599,191.742,166.045,206.136,190.027,200.013z"/>
                  </svg>
                </EditButton>
              </React.Fragment>
            ) : null }
          </div>
        ) : null }
        <Modal show={showDeleteModal} transitionSpeed={1} closeOnClickAnywhere onClose={() => this.toggleDeleteModal()}>
          <h2>Är du säker på att du vill ta bort?</h2>
          <Button onClick={this.deleteItem.bind(this)} text="Ta bort" style={{marginLeft: '8px'}}/>
          <Button onClick={() => this.toggleDeleteModal()} text="Behåll" style={{marginLeft: '8px'}}/>
        </Modal>
      </div>
    );
  }
}


const absolutePositioningWrapperStyle = {
  position: 'absolute',
  top: 0,
  bottom: '-13px',
  left: '-10vw',
  display: 'flex',
  width: '32px',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column'
};