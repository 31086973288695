import * as React from 'react';
import {schema} from '../config';
import * as firebase from 'firebase';
import { FirestoreCollection } from 'react-firestore';
import { AddButton } from '../components/Button';
import DataEditor from '../components/DataEditor';

export default class Contact extends React.Component {

  toggleAddItem() {
    const { schemaType } = this.props;
    let newItem = {};
    for (var key in schema[schemaType].keys) {
      newItem[key] = schema[schemaType].keys[key].placeholder;
    }
    firebase.firestore().collection(schemaType).add(newItem);
  }

  render() {
    const { schemaType, editing } = this.props;
    return (
      <FirestoreCollection
        path={schemaType}
        sort="created:desc"
        render={({ isLoading, data }) => {
          return isLoading ? (
            <div> laddar </div>
          ) : (
            <div>
              <div className="data-content-wrapper">
                {
                  editing ? (
                    <React.Fragment>
                      <div style={{height: '64px'}}/>
                      <AddButton onClick={this.toggleAddItem.bind(this)} label={schema[schemaType].newString}/>
                    </React.Fragment>) 
                    : null
                }
                {data.map(item => {
                  const props = Object.assign({}, this.props, {item:item});
                  return (
                    <div className="data-content-wrapper__item" key={item.id}>
                      <DataEditor {...props}/>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        }}
      />
    );
  }
}
