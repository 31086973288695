export const schema = {
  start: {
    newString: 'Lägg till ny startsektion..',
    keys: {
      image: {type: 'image', placeholder: 'Bild'},
      title: {type: 'string', placeholder: 'titel'},
      description: {type: 'string', placeholder: 'beskrivning'},
      measurements: {type: 'string', placeholder: 'mått'},
      created: {type: 'date', placeholder: Math.round(new Date().getTime() / 1000)}
    }
  },
  works: {
    newString: 'Lägg till nytt projekt..',
    keys: {
      image: {type: 'image', placeholder: 'Bild eller bildlänk'},
      title: {type: 'string', placeholder: 'titel'},
      description: {type: 'string', placeholder: 'beskrivning'},
      measurements: {type: 'string', placeholder: 'mått'},
      created: {type: 'date', placeholder: Math.round(new Date().getTime() / 1000)}
    }
  },
  news: {
    newString: 'Lägg till ny nyhet..',
    keys: {
      title: {type: 'string', placeholder: 'titel'},
      location: {type: 'string', placeholder: 'plats'},
      link: {type: 'url', placeholder: 'länk'},
      description: {type: 'string', placeholder: 'beskrivning'},
      date: {type: 'date', placeholder: 'titel'},
      created: {type: 'date', placeholder: Math.round(new Date().getTime() / 1000)}
    }
  },
  info: {
    newString: 'Lägg till ny information ..',
    keys: {
      description: {type: 'string', placeholder: 'beskrivning'},
      created: {type: 'date', placeholder: Math.round(new Date().getTime() / 1000)}
    }
  },
  contact: {
    newString: 'Lägg till ny kontaktinformation ..',
    keys: {
      description: {type: 'string', placeholder: 'beskrivning'},
      created: {type: 'date', placeholder: Math.round(new Date().getTime() / 1000)}
    }
  }
};