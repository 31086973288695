import * as React from 'react';
import {Button} from './Button';
import { auth, provider } from '../app.js';

export default class LoginForm extends React.Component {


  logout() {
    this.props.toggleEditing(false, () => {
      auth.signOut();
    });
  }

  login() {
    auth.signInWithPopup(provider) 
    .then(() => {
      this.props.history.push('/');
    });
  }

  render() {
    return !this.props.user ? <Button text="Logga in" onClick={this.login.bind(this)}/> : <Button text="Logga ut" onClick={this.logout.bind(this)}/>
    ;
  }
}
