import React from 'react';
import ImageComponent from './ImageComponent';
import {Button} from './Button';


export default class ImageUploader extends React.Component {
  constructor(props) {
    super(props);
    const imageUrl = this.props.imageUrl ? this.props.imageUrl : false;
    this.state = {
      uploading: false,
      imageUrl: imageUrl
    };
    this.inputRef = React.createRef();
  }

  openFilePicker() {
    this.inputRef.current.focus();
    this.inputRef.current.click();
  }

  render() {
    const {uploading, imageUrl} = this.state;
    return (
      <div style={{position: 'relative'}}>
        <ImageComponent isUploading={uploading} image={imageUrl ? imageUrl : 'http://via.placeholder.com/1000x1000'} style={{maxWidth: '100%'}}/>
        {!uploading ? (
          <div style={absolutePositioningWrapperStyle}>
            <div style={uploadClickTextStyle}>
              <Button onClick={this.openFilePicker.bind(this)} text="Ladda upp ny"/>
            </div>
            <input 
              style={{opacity: 0}}
              ref={this.inputRef}
              type="file" 
              onChange={
                (event) => {
                  const file = event.target.files[0];
                  let reader = new FileReader();
                  reader.readAsDataURL(file);
                  reader.onload = (e) => {
                    this.setState({imageUrl: e.target.result});
                  };
                  this.props.uploadImage(event, this.props.itemKey, (isUploading) => {
                    this.setState({uploading: isUploading});
                  }, (uploadedFileUrl) => {
                    this.setState({imageUrl: uploadedFileUrl});
                  });
                }
              }
            />
          </div>
        ) : null}
      </div>
    );
  }
}

const absolutePositioningWrapperStyle = {
  position: 'absolute',
  bottom: 0,
  right: 0,
  left: 0,
  top: 0,
  background: 'rgba(255,255,255,0.7)',
  cursor: 'pointer',
  textAlign: 'center',
  fontWeight: 'bold'
};

const uploadClickTextStyle = {
  zIndex: 1,
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)'
};