import React from 'react';

const defaultStyle = {
  display: 'inline-block',
  color: 'white',
  backgroundColor: '#4285f4',
  width: 'auto',
  height: '40px',
  padding: '0 16px',
  lineHeight: '40px',
  textAlign: 'center',
  cursor: 'pointer',
  fontSize: '16px'
};

export class Button extends React.Component {
  render() {
    const {onClick, style, text} = this.props;
    return (
      <div style={Object.assign({}, defaultStyle, style)} onClick={onClick}>
        {text}
      </div>
    );
  }
}

const absolutePositioningWrapperStyle = {
  position:'absolute',
  top: '-8px',
  left: '-10vw',
  right: 0,
  cursor: 'pointer'
};
const addButtonStyle = {
  background: '#333',
  borderRadius: '50%',
  borderTopRightRadius: 0,
  width: '32px',
  height: '32px',
  lineHeight: '32px',
  textAlign: 'center',
  cursor: 'pointer',
  transform: 'rotate(45deg)',
  marginBottom: '8px',
  flex: 0
};
const labelTextStyle = {
  position:'absolute',
  top: 0,
  left: '10vw',
  lineHeight: '40px',
  fontSize: '16px',
  color: '#666'
};
export class AddButton extends React.Component {
  render() {
    const {onClick, style, label} = this.props;
    return (
      <React.Fragment>
        <div style={absolutePositioningWrapperStyle} onClick={onClick}>
          <small style={labelTextStyle}>{label}</small>
          <div style={Object.assign({}, addButtonStyle, style)} className="shadow-left">
            <svg style={{'verticalAlign':'middle'}} width="16" height="16" viewBox="0 0 26 26">
              <path transform="rotate(0, 16, 16)" stroke="white" fill="#fff" d="M25.4869727,24.07134 C25.8914392,24.4758065 25.8914392,25.0825062 25.4869727,25.4869727 C25.2847395,25.689206 25.0825062,25.7903226 24.7791563,25.7903226 C24.4758065,25.7903226 24.2735732,25.689206 24.07134,25.4869727 L12.6451613,14.060794 L1.21898263,25.4869727 C1.01674938,25.689206 0.814516129,25.7903226 0.511166253,25.7903226 C0.207816377,25.7903226 0.00558312655,25.689206 -0.196650124,25.4869727 C-0.601116625,25.0825062 -0.601116625,24.4758065 -0.196650124,24.07134 L11.2295285,12.6451613 L-0.196650124,1.21898263 C-0.601116625,0.814516129 -0.601116625,0.207816377 -0.196650124,-0.196650124 C0.207816377,-0.601116625 0.814516129,-0.601116625 1.21898263,-0.196650124 L12.6451613,11.2295285 L24.07134,-0.196650124 C24.4758065,-0.601116625 25.0825062,-0.601116625 25.4869727,-0.196650124 C25.8914392,0.207816377 25.8914392,0.814516129 25.4869727,1.21898263 L14.060794,12.6451613 L25.4869727,24.07134 Z" id="Path"></path>
            </svg>
          </div>
        </div>

      </React.Fragment>
    );
  }
}



const editButtonStyle = {
  background: '#fafafa',
  borderRadius: '50%',
  borderTopRightRadius: 0,
  width: '32px',
  height: '32px',
  lineHeight: '32px',
  textAlign: 'center',
  cursor: 'pointer',
  transform: 'rotate(45deg)',
  marginBottom: '8px',
  flex: 0
};
export class EditButton extends React.Component {
  render() {
    const {onClick, children, style} = this.props;
    return (
      <React.Fragment>
        <div onClick={onClick} style={Object.assign({}, editButtonStyle, style)} className="shadow-left">
          {children}
        </div>
      </React.Fragment>
    );
  }
}