import React from 'react';
import {Route} from 'react-router-dom';

import Logo from '../components/Logo.js';
import Navigation from './Navigation.js';

import StartPage from '../pages/StartPage.js';
import InfoPage from '../pages/InfoPage.js';
import WorksPage from '../pages/WorksPage.js';
import NewsPage from '../pages/NewsPage.js';
import ContactPage from '../pages/ContactPage.js';
import LoginPage from '../pages/LoginPage.js';



export default class UI extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  startPage(props) {
    return <StartPage
      {...this.props}
      {...props}
    />;
  }
  infoPage(props) {
    return <InfoPage
      {...this.props}
      {...props}
    />;
  }
  worksPage(props) {
    return <WorksPage
      {...this.props}
      {...props}
    />;
  }
  newsPage(props) {
    return <NewsPage
      {...this.props}
      {...props}
    />;
  }
  contactPage(props) {
    return <ContactPage
      {...this.props}
      {...props}
    />;
  }
  loginPage(props) {
    return <LoginPage
      {...this.props}
      {...props}
    />;
  }

  render() {
    return(
      <React.Fragment>

        <Logo {...this.props}/>
        <Navigation {...this.props}/>

        <Route exact path="/" component={this.startPage.bind(this)} />
        <Route exact path="/info" component={this.infoPage.bind(this)} />
        <Route exact path="/works" component={this.worksPage.bind(this)} />
        {/*<Route exact path="/news" component={this.newsPage.bind(this)} />*/}
        <Route exact path="/contact" component={this.contactPage.bind(this)} />
        <Route exact path="/login" component={this.loginPage.bind(this)} />

      </React.Fragment>
    );
  }
}
