import 'babel-polyfill';
import * as React from 'react';
import ReactDOM from 'react-dom';
import * as firebase from 'firebase';
import '@firebase/firestore';
import { FirestoreProvider } from 'react-firestore';
import {HashRouter as Router} from 'react-router-dom';

import UI from './containers/UI.js';


var config = {
  apiKey: 'AIzaSyAKejbBbil3DdN2b4ygvY7u8U4A5hGPwXw',
  authDomain: 'konstfolio.firebaseapp.com',
  databaseURL: 'https://konstfolio.firebaseio.com',
  projectId: 'konstfolio',
  storageBucket: 'konstfolio.appspot.com',
  messagingSenderId: '474260739205'
};
firebase.initializeApp(config);
export const provider = new firebase.auth.GoogleAuthProvider();
export const auth = firebase.auth();
export const db = firebase.firestore();

db.settings({timestampsInSnapshots: true});

class Loader extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      user: false
    };
  }

  componentWillMount() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.setState({user: user});
      } else {
        this.setState({user: false});
      }
    });
  }

  toggleEditing(boolean, cb) {
    if (typeof boolean !== 'boolean') {
      this.setState({
        editing: !this.state.editing
      });
      if (cb) cb(!this.state.editing);
    } else {
      this.setState({
        editing: boolean
      });
      if (cb) cb(boolean);
    }
  }

  render() {
    const {editing, user} = this.state;
    return (
      <FirestoreProvider firebase={firebase} user={user}>
        <Router user={user}>
          <UI editing={editing} toggleEditing={this.toggleEditing.bind(this)} user={user}/>
        </Router>
      </FirestoreProvider>
    );
  }
}

ReactDOM.render(
  <Loader/>
, document.querySelector('#ui'));
