import React from 'react';
import {NavLink} from 'react-router-dom';
import EditingToggle from '../components/EditingToggle';

export default class Navigation extends React.Component {
  render() {
    return (
      <React.Fragment>
        <EditingToggle {...this.props} />
        <div className="navigation">
          <NavLink to="/info" exact>
            Info
          </NavLink>
          <span>/</span>
          <NavLink to="/works">
            Works
          </NavLink>
          {/*
            <span>/</span>
            <NavLink to="/news">
              News
            </NavLink>
          */}
          <span>/</span>
          <NavLink to="/contact">
            Contact
          </NavLink>
        </div>
        {/*<hr/>*/}
      </React.Fragment>
    );
  }
}

